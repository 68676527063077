.buttons {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 500;
  text-align: center;
}

.btn {
  background: #303336;
  color: #ffffff;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.btn > span {
  position: relative;
  bottom: 4px;
}

.centerBtn {
  width: 50px;
  height: 50px;
}

.rotate {
  display: flex;
  justify-content: space-around;
}

.btnWrapper {
  margin-top: 20px;
}
