/* hide cesium logo */
.cesium-viewer-bottom {
  display: none;
}

.cesium-infoBox {
  max-width: 300px;
  max-height: 250px;
  overflow: hidden;
}
.cesium-infoBox-iframe {
  max-height: 220px !important;
  overflow: auto;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .cesium-infoBox {
    max-width: 250px;
    font-size: 14px;
  }
}
